import { object } from 'prop-types';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

export default function withGATracker(WrappedComponent) {
  const trackPage = (page) => ReactGA.send({ hitType: 'pageview', page });

  const HOC = (props) => {
    const { location } = props;

    useEffect(() => {
      const page = location.pathname + location.hash;

      trackPage(page);
    }, [location.pathname, location.hash]);

    return <WrappedComponent {...props} />;
  };

  HOC.propTypes = {
    location: object.isRequired,
  };

  return HOC;
}
