export const constants = {
  LEASE_RENEWALS: 'leaseRenewals',
  LEASING: 'leasing',
  EARLY_LEASE_TERMINATION: 'earlyLeaseTermination',
  PROPERTY_TURNS: 'propertyTurns',
  NEW_OWNERS: 'newOwners',
  OWNERS_PENDING_TERMINATION: 'ownersPendingTermination',
  TENANTS_PENDING_TERMINATION: 'tenantsPendingTermination',
  MARKETING_UPDATES: 'marketingUpdates',
  SIMPLE_SIGNUP: 'simpleSignup',
  ACTIVE_PROPERTY_LISTINGS: 'activePropertyListings',
  RENTAL_LISTING_SYNDICATION: 'rentalListingSyndication',
  PROPERTYWARE_AUDIT: 'propertywareAudit',
  ACCOUNTS_RECEIVABLE_ONQ: 'accountsReceivableOnQ',
  ACCOUNTS_RECEIVABLE_MAMMOTH: 'accountsReceivableMammoth',
  MARKETING_FEEDBACK: 'marketingFeedback',
  ACCOUNTS_PAYABLE_ONQ: 'accountsPayableOnQ',
  ACCOUNTS_PAYABLE_MAMMOTH: 'accountsPayableMammoth',
  COMPANY_CARD_RECONCILIATION: 'companyCardReconciliation',
  WORK_ORDERS: 'workOrders',
  INSPECTIONS: 'inspections',
  POST_OFFICE: 'postOffice',
  PRICE_BOOK: 'priceBook',
  RENTAL_LISTING_LEADS: 'rentalListingLeads',
  RENTAL_MARKET_ANALYSIS: 'rentalMarketAnalysis',
  LOCKBOX_MANAGER: 'lockboxManager',
  LOCKER_MANAGER: 'lockerManager',
  PROPERTY_KEY_TAG_EVENTS: 'propertyKeyTagEvents',
  PROPERTY_KEY_TAGS: 'propertyKeyTags',
  DAILY_KEY_TAG_PULL: 'dailyKeyTagPull',
  ROUND_ROBIN_ASSIGNEES: 'roundRobinAssignees',
  PAYMENT_PLAN: 'paymentPlan',
  FASTFIELDS_INSPECTIONS: 'fastfieldsInspections',
  DOCUMENT_PROCESSING: 'documentProcessing',
  MISSING_TPT: 'missingTpt',
  TERMINATE_TPT: 'terminateTpt',
  OWNER_PROSPECTS: 'ownerProspects',
  PORTAL_EVENT_LOG: 'portalEventLog',
  VENDORS: 'vendors',
  RENTAL_RATE_CHANGES: 'rentalRateChanges',
};

export const titles = {
  [constants.LEASE_RENEWALS]: 'Lease Renewals',
  [constants.LEASING]: 'Leasing',
  [constants.EARLY_LEASE_TERMINATION]: 'Early Lease Termination',
  [constants.PROPERTY_TURNS]: 'Property Turns',
  [constants.NEW_OWNERS]: 'New Owners',
  [constants.OWNERS_PENDING_TERMINATION]: 'Owners Pending Termination',
  [constants.TENANTS_PENDING_TERMINATION]: 'Tenants Pending Termination',
  [constants.MARKETING_UPDATES]: 'Marketing Updates',
  [constants.SIMPLE_SIGNUP]: 'Simple Signup',
  [constants.ACTIVE_PROPERTY_LISTINGS]: 'Active Property Listings',
  [constants.RENTAL_LISTING_SYNDICATION]: 'Rental Listing Syndication',
  [constants.PROPERTYWARE_AUDIT]: 'Propertyware Audit',
  [constants.ACCOUNTS_RECEIVABLE_ONQ]: 'On Q Accounts Receivable',
  [constants.ACCOUNTS_RECEIVABLE_MAMMOTH]: 'Mammoth Accounts Receivable',
  [constants.MARKETING_FEEDBACK]: 'Marketing Feedback',
  [constants.ACCOUNTS_PAYABLE_ONQ]: 'On Q Accounts Payable',
  [constants.ACCOUNTS_PAYABLE_MAMMOTH]: 'Mammoth Accounts Payable',
  [constants.COMPANY_CARD_RECONCILIATION]: 'Company Card Reconciliation',
  [constants.WORK_ORDERS]: 'Work Orders',
  [constants.INSPECTIONS]: 'Inspections',
  [constants.POST_OFFICE]: 'Post Office',
  [constants.PRICE_BOOK]: 'Price Book',
  [constants.RENTAL_LISTING_LEADS]: 'Rental Listing Leads',
  [constants.RENTAL_MARKET_ANALYSIS]: 'Rental Market Analysis',
  [constants.LOCKBOX_MANAGER]: 'Lockbox Manager',
  [constants.LOCKER_MANAGER]: 'Locker Manager',
  [constants.PROPERTY_KEY_TAG_EVENTS]: 'Property Key Tag Events',
  [constants.PROPERTY_KEY_TAGS]: 'Property Key Tags',
  [constants.DAILY_KEY_TAG_PULL]: 'Daily Key Tag Pull',
  [constants.ROUND_ROBIN_ASSIGNEES]: 'Round Robin Assignees',
  [constants.PAYMENT_PLAN]: 'Payment Plan',
  [constants.FASTFIELDS_INSPECTIONS]: 'Fastfields Inspections',
  [constants.DOCUMENT_PROCESSING]: 'Document Processing',
  [constants.MISSING_TPT]: 'Missing TPT',
  [constants.TERMINATE_TPT]: 'Terminate TPT',
  [constants.OWNER_PROSPECTS]: 'Owner Prospects',
  [constants.PORTAL_EVENT_LOG]: 'Portal Event Log',
  [constants.VENDORS]: 'Vendors',
  [constants.RENTAL_RATE_CHANGES]: 'Rental Rate Changes',
};

const groups = {
  [constants.LEASE_RENEWALS]: 'Property Management',
  [constants.LEASING]: 'Property Management',
  [constants.EARLY_LEASE_TERMINATION]: 'Property Management',
  [constants.PROPERTY_TURNS]: 'Property Management',
  [constants.NEW_OWNERS]: 'Property Management',
  [constants.OWNERS_PENDING_TERMINATION]: 'Property Management',
  [constants.TENANTS_PENDING_TERMINATION]: 'Property Management',
  [constants.MARKETING_UPDATES]: 'Marketing',
  [constants.SIMPLE_SIGNUP]: 'Sales',
  [constants.OWNER_PROSPECTS]: 'Sales',
  [constants.ACTIVE_PROPERTY_LISTINGS]: 'Marketing',
  [constants.RENTAL_LISTING_SYNDICATION]: 'Marketing',
  [constants.PROPERTYWARE_AUDIT]: 'System',
  [constants.ACCOUNTS_RECEIVABLE_ONQ]: 'Accounting',
  [constants.ACCOUNTS_RECEIVABLE_MAMMOTH]: 'Accounting',
  [constants.MARKETING_FEEDBACK]: 'Marketing',
  [constants.ACCOUNTS_PAYABLE_ONQ]: 'Accounting',
  [constants.ACCOUNTS_PAYABLE_MAMMOTH]: 'Accounting',
  [constants.COMPANY_CARD_RECONCILIATION]: 'Accounting',
  [constants.WORK_ORDERS]: 'Work Orders',
  [constants.INSPECTIONS]: 'Property Management',
  [constants.POST_OFFICE]: 'Property Management',
  [constants.PRICE_BOOK]: 'Work Orders',
  [constants.RENTAL_LISTING_LEADS]: 'Sales',
  [constants.RENTAL_MARKET_ANALYSIS]: 'Sales',
  [constants.LOCKBOX_MANAGER]: 'Property Management',
  [constants.LOCKER_MANAGER]: 'Property Management',
  [constants.PROPERTY_KEY_TAG_EVENTS]: 'Property Management',
  [constants.PROPERTY_KEY_TAGS]: 'Property Management',
  [constants.DAILY_KEY_TAG_PULL]: 'Property Management',
  [constants.ROUND_ROBIN_ASSIGNEES]: 'System',
  [constants.PAYMENT_PLAN]: 'Property Management',
  [constants.FASTFIELDS_INSPECTIONS]: 'Property Management',
  [constants.DOCUMENT_PROCESSING]: 'Property Management',
  [constants.MISSING_TPT]: 'Property Management',
  [constants.TERMINATE_TPT]: 'Property Management',
  [constants.PORTAL_EVENT_LOG]: 'System',
  [constants.VENDORS]: 'Work Orders',
  [constants.RENTAL_RATE_CHANGES]: 'Sales',
};

export const forDropdown = Object.keys(titles).map((value) => ({
  value,
  label: titles[value],
  group: groups[value],
}));

export const tooltips = {
  [constants.LEASE_RENEWALS]:
    'Leases with active lease renewal addendums or set Leases to Month to Month',
  [constants.PROPERTY_TURNS]:
    'Properties where Owner Contract is Active or Pending Termination and Tenant Contract is missing, expired, or Pending Termination',
  [constants.NEW_OWNERS]: 'Owner Contracts that started in the last 60 days',
  [constants.OWNERS_PENDING_TERMINATION]:
    'Owner Contracts that are Pending Termination',
  [constants.TENANTS_PENDING_TERMINATION]:
    'Tenant Contracts that are Pending Termination',
  [constants.MARKETING_UPDATES]:
    'Listings that are not Cancelled or Off the Market',
  [constants.SIMPLE_SIGNUP]:
    'New Owner Opportunities that are in Contract Signed or Closed Won and has not been marked completed by the onboarding team',
  [constants.ACTIVE_PROPERTY_LISTINGS]:
    'Listings that are not Cancelled or Off the Market',
  [constants.RENTAL_LISTING_SYNDICATION]:
    'Listings that are not in Created, Waiting to Market, Cancelled, Off the Market, Lease Agreement Pending, or Holding Deposit Confirmed and Marketing Rental Rate and Application URL are not empty',
  [constants.PROPERTYWARE_AUDIT]:
    'Actively managed properties that are missing any of the following: Propertyware Entity Id, Propertyware Id, or Propertyware Abberviation',
  [constants.ACCOUNTS_RECEIVABLE_ONQ]:
    'On Q Invoices where Balance Due is not $0',
  [constants.ACCOUNTS_RECEIVABLE_MAMMOTH]:
    'Mammoth Invoices where Balance Due is not $0',
  [constants.MARKETING_FEEDBACK]:
    'Listings that are not Cancelled or Off the Market',
  [constants.ACCOUNTS_PAYABLE_ONQ]:
    'On Q expenses with a Balance Due and Due Date has passed. Expenses must also have Approval to Pay, or the related work order has been closed',
  [constants.ACCOUNTS_PAYABLE_MAMMOTH]:
    'Mammoth expenses with a Balance Due and Due Date has passed. Expenses must also have Approval to Pay, or the related work order has been closed',
  [constants.COMPANY_CARD_RECONCILIATION]:
    'On Q Expenses paid for with a company card that have not been reconciled',
};
